import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "patient login",
    component: () => import("../views/patient/Patient_logView.vue"),
  },
  {
    path: "/patient_signup",
    name: "patient_signup",
    component: () => import("../views/patient/Patient_signupView.vue"),
  },

  {
    path: "/doctorinfo",
    name: "docinfo",
    component: () => import("../views/doctor/mobile/doctorinfo.vue"),
  },

  {
    path: "/report",
    name: "report",
    component: () => import("../views/doctor/mobile/report.vue"),
  },
  {
    path: "/makeappomob",
    name: "makeappointmentmobile",
    component: () => import("../views/patient/mobile/MakeAppoMobile.vue"),
  },
  {
    path: "/inbox",
    name: "inboxmob",
    component: () => import("../views/patient/mobile/Inboxmob.vue"),
  },

  {
    path: "/forgetpass",
    name: "forgetpass",
    component: () => import("../views/patient/forgetpass.vue"),
  },

  {
    path: "/account_confirmation",
    name: "account_confirmation",
    component: () => import("../views/patient/AccConfView.vue"),
  },
  {
    path: "/patientdashboard",
    name: "patientdashboard",
    component: () => import("../views/patient/PatientDashboardView.vue"),
  },
  {
    path: "/department",
    name: "department",
    component: () => import("../views/patient/DepartmentView.vue"),
  },
  {
    path: "/doctorinfo/:dept",
    name: "doctorinfo",
    component: () => import("../views/patient/DoctorInfoView.vue"),
  },
  {
    path: "/userinfo",
    name: "userinfo",
    component: () => import("../views/patient/UserInfoView.vue"),
  },
  {
    path: "/useredit",
    name: "useredit",
    component: () => import("../views/patient/UserEditView.vue"),
  },
  {
    path: "/doctor",
    name: "doctor_login",
    component: () => import("../views/doctor/Doctor_LogView.vue"),
  },
  {
    path: "/doctordashboard",
    name: "doctordashboard",
    component: () => import("../views/doctor/DoctorDashboardView.vue"),
  },
  {
    path: "/aboutdoctor",
    name: "aboutdoctor",
    component: () => import("../views/doctor/AboutDoctorView.vue"),
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: () => import("../views/patient/PrivacyPolicy.vue"),
  },
  {
    path: "/account-delete",
    name: "account-delete",
    component: () => import("../views/patient/AccountDelete.vue"),
  },
];

/* router.beforeEach((to, from, next) => {
  const authenticatedUser = null;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  // Check for protected route
  if (requiresAuth && !authenticatedUser) next("login");
  else next();
}); */

const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
